.navbar {
    background-color: hsla(0, 0%, 0%, 0.99);
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 60px;
    align-items: center;
}

.navImg1 {
    height: 90px;
}

.navImg2 {
    height: 100px;
}

.navbar p {
    color: white;
    font-size: 2rem;
    text-align: center;
}

@media (min-width: 320px) and (max-width: 640px) {
    .navbar {
        height: 30px;
    }

    .navImg1 {
        height: 50px;
    }

    .navImg2 {
        height: 50px;
    }

    .navbar p {
        font-size: 1rem;

    }
}