
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.Drama-section {
    background-color:black;
    height: auto;
    margin-top: -70px;
}

.club-name {
    background-color: hsla(222, 95%, 55%, 0.9);
    padding-left: 15px;
    padding-right: 25px;
    border-radius: 5%;

}

.drama-text {
    text-align: center;
    width: 1000px;
    margin-left: 280px;
    color: white;
    font-size: 22px;
}

.drama-team {
    display: flex;
    justify-content: center;


}

.drama-team img {
    border-top: 5pt solid brown;
    border-bottom: 5pt solid brown;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 300px;
}

.drama-h1 {
    text-align: center;
    margin-bottom: 0px;
    padding-top: 100px;
    font-size: 100px;
    padding: 50px;
    color: yellow;
    font-style: italic;
}
.drama-logo{
    border-radius: 50%;
}
@media (min-width:320px) and (max-width:640px) {
    .drama-h1 {
        font-size: 27.8px;
        margin: 40px 0px 30px;
    }

    .drama-team {
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
    }

    .drama-text {
        margin: 35px;
        width: auto;
        margin-top: -50px;
        font-size: 18px;
    }
   
}
.clubs-footer {
    background-color:black;
    height: fit-content;
    color: white;
    display: flex;
    justify-content: center;
}

.clubs-footer ul {
    display: flex;
    list-style-type: none;
}

.clubs-footer ul li {
    margin-right: 50px;
}