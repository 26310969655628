.media-section {
    position: relative;
}

.m-imgsec {
    display: flex;
    justify-content: space-evenly;

}

.m-text {
    background-color: #26423e;
    margin-top: -115px;
    padding: 50px;
}

.m-head {
    text-align: center;
    position: absolute;
    top: 15%;
    left: 35%;

}

.head1 {
    color: orange;
    font-size: 150px;
    font-weight: bold;
    margin-top: -400px;
}

.head2 {
    color: yellow;
    font-style: italic;
    font-size: 100px;
    font-weight: bold;
}

.side-text {
    position: absolute;
    color: white;
    font-size: 22px;
}

.m-about {
    padding-top: 150px;
    color: orange;
    font-size: 22px;
}

.media-hr {
    border: 2px solid yellow;
    position: relative;
    top: 130px;
    width: 200px;
    margin-left: 0px;
}

@media (min-width:320px) and (max-width:640px) {
    .m-head {
        top: 10%;
        left: 35%;
    }

    .head1 {
        font-size: 50px;
        margin-top: -75px;
        margin-left: -10px;
    }

    .head2 {
        font-size: 26px;
        transform: scaleX(1.5);
        margin-top: -100px;
    }

    .m-imgsec {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .side-text {
        margin-top: 100px;
        font-size: 22px;
    }

    .media-hr {
        top: 160px;
    }

    .m-about {
        padding-top: 180px;
        font-size: 18px;
    }

}