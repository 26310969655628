.music-section {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-row: 1.2fr 1fr;
}

.music-head {
    grid-area: 1/1/2/2;
    background-color: black;
    border-top: 50px solid #86868b;
    border-left: 50px solid #86868b;
    height: 65vh;
}

.music-img {
    grid-area: 1/2/3/3;
    background-color: black;
    border-left: 50px solid #86868b;
    padding: 5%;
}

.music-img img {

}

.music-img p {
    color: white;
    font-size: 25px;

    margin-top: 15%;
    text-align: center;
}

.music-text {
    grid-area: 2/1/3/2;
    border-left: 50px solid #86868b;
    background-color: #cbdba4;
    margin-top: -10%;
}

.music-text p {
    color: black;
    font-size: 25px;

    padding: 5%;
}

.mus-head2 {
    position: relative;
    z-index: 1;
    /* Lower z-index to appear behind mus-head1 */
    color: #cbdba4;
    font-size: 250px;
    font-weight: bold;
    transform: scaleY(1.7);
    /* Keeps the scaling you had */
    margin-left: 10%;
    top:-55%;
}

.mus-head1 {
    position: relative;
    z-index: 2;
    /* This will ensure it appears above mus-head2 */
    color: white;
    font-size: 150px;
    font-weight: bold;
    margin-left: 17%;
    top: -150%;
    letter-spacing: 20px;
    transform: scaleY(0.6);
}
.music-img img{
    width: 100%;
}

@media (min-width:320px) and (max-width:640px) {
    .music-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .music-head {
        border: none;
        height: 20%;
        width: 100%;
    }

    .music-img {
        border: none;
    }

    .music-text {
        border: none;
    }

    .mus-head2 {
        font-size: 50px;
        top: 0%;
        left: 20%;
    }

    .mus-head1 {
        font-size: 25px;
        top: 0%;
        transform: scaleX(0.9);
        left:10%;
    }

    .music-img img {
        width: 300px;
    }

    .music-img p {
        font-size: 18px;
        margin-top: 10px;
    }

    .music-text {
        margin-top: 0px;
    }

    .music-text p {
        font-size: 18px;

    }
}