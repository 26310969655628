@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
.oratory-section {
    background-color: #002659;
    height: auto;
    display: grid;
    grid-template-rows: 0.7fr 0.7fr 0.7fr;
    grid-auto-columns: 0.7fr 0.7fr 0.7fr;
}

.header {
    grid-area: 1/1/span 1/span 6;
}

.header h1 {
    text-align: 'center';
    font-size: 90px;
    padding-top: 80px;
    color: yellow;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
}

.text1 {
    grid-area: 2/1/span 1/span 4;
    text-align: center;
    font-size: 25px;
    padding: 10px;
    padding-left: 50px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
}

.img1 {
    grid-area: 3/1/span 1/span 2;
}

.img2 {
    grid-area: 3/3/span 1/span 2;
}

.text1 p,
.text2 p {
    color: white;
}

.img3 {
    grid-area: 2/5/span 1/span 2;
}

.text2 {
    grid-area: 3/5/span 1/span 2;
    text-align: center;
    font-size: 25px;
    padding-right: 60px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
}
.clubs-footer {
    background-color: black;
    height: fit-content;
    color: white;
    margin-top: 0px;
    display: flex;
    justify-content: center;
}

.clubs-footer ul {
    display: flex;
    list-style-type: none;
}

.clubs-footer ul li {
    margin-right: 50px;
}
@media (min-width: 320px) and (max-width: 640px) {
    .oratory-section {
        display: flex;
        flex-direction: column;
        
    }

    .img1 {
        margin-left: -150px;
    }

    .header h1 {
        font-size: 30px;
        margin-top: -30px;
    }

    .text1 p {
        font-size: 18px;
        margin-right: 40px;
    }

    .text2 p {
        font-size: 18px;
        margin-left: 30px;
    }
    .clubs-footer img {
        margin: 30px 0px 30px 40px;
        
    }
}
