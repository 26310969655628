@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url(https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular);
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Salsa&display=swap');
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
  justify-content: center;
}

.incubator-container {
  min-height: 100vh;
  background-image: url('../assets/ncabg.jpg');
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: 150px; 
  position: relative;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  text-align: left;
}

.incubator-title{
  font-size: 68px;
  padding: 10px 40px;
  text-shadow: 
        0 0 5px rgba(255, 255, 255, 0.7),
        0 0 10px rgba(255, 255, 255, 0.5),
        0 0 15px rgba(255, 255, 255, 0.3);
  margin-top: 0;   
}

.incubator-container {
  min-height: 100vh;
  background-image: url('../assets/ncabg.jpg');
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: 100px; 
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
}


.row {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0;
}

.item {
  display: flex;
  align-items: center;
  gap: 0px;
}

.item.right {
  flex-direction: row;
}

.item.left {
  flex-direction: row-reverse;
}

.incubator-img{
  width: 200px;
  height: 205px;
  
}
.row:last-child {
  justify-content: space-around; 
  margin: 5px 0;
}


.row:last-child {
  justify-content: space-around; 
  margin: 5px 0; 
}

.incubator-img {
  width: 180px;
  height: 185px; 
}

.item span {
  padding: 10px 12px;
  font-size: 22px; 
}

.item span {
  background-color: #ffed00;
  font-family: "Salsa", cursive;
  font-weight: 400;
  font-style: normal;
  color: black;
  padding: 13px 15px;
  font-size: 25px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: -50px;
  z-index: 1;
}

.img1{
  width: 250px;
}
@media (max-width: 1200px) and (min-width: 850px){
  .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }

  .row:nth-last-child(2) {
    grid-template-columns: repeat(2, 1fr);
  }

  .otaku {
    grid-column: span 2;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: auto;
    position: relative;
  }

.incubator-img{
    width: 200px;
    height: 210px;
    margin: 0;
  }

  .item span {
    font-size: 30px;
    padding: 30px 45px;
    margin: 0 20px 0 -20px;
    height: 45px;
    text-align: center;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .incubator-container {
    padding-top: 30px;
    padding-right: 20px;
  }

  .incubator-title {
    font-size: 4rem;
  }
}
@media (max-width: 850px) and (min-width: 480px){
  .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }

  .row:nth-last-child(2) {
    grid-template-columns: repeat(2, 1fr);
  }

  .otaku {
    grid-column: span 2;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: auto;
    position: relative;
  }

  .incubator-img {
    width: 120px;
    height: 130px;
    margin: 0;
  }

  .item span {
    font-size: 18px;
    padding: 15px 26px;
    margin: 0 20px 0 -20px;
    height: 45px;
    text-align: center;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .yellow-text-box {
    height: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .incubator-container {
    padding-top: 30px;
    padding-right: 20px;
  }

  .incubator-title {
    font-size: 3.5rem;
  }
}
@media (max-width: 480px) and (min-width:370px) {
  .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }

  .row:nth-last-child(2) {
    grid-template-columns: repeat(2, 1fr);
  }

  .otaku {
    grid-column: span 2;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: auto;
    position: relative;
  }

  .item img {
    width: 80px !important;
    height: 85px !important;
    margin: 0;
  }

  .item span {
    font-size: 14px;
    padding: 5px 6px;
    margin: 0 20px 0 -20px;
    height: 45px;
    text-align: center;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .yellow-text-box {
    height: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .incubator-container {
    padding-top: 30px;
    padding-right: 20px;
  }

  .incubator-title {
    font-size: 3rem;
  }
}
@media (max-width:365px){
 
  .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }

  .row:nth-last-child(2) {
    grid-template-columns: repeat(2, 1fr);
  }

  .otaku {
    grid-column: span 2;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: auto;
    position: relative;
    margin-right: -8vw;
    margin-left: -3vw;
  }

  .item img {
    width: 80px !important;
    height: 85px !important;
    margin: 0;
  }

  .item span {
    font-size: 14px;
    padding: 5px 6px;
    margin: 0 20px 0 -20px;
    height: 45px;
    text-align: center;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .yellow-text-box {
    height: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .incubator-container {
    padding-top: 30px;
    padding-right: 20px;
  }

  .incubator-title {
    font-size: 3rem;
  }
}


