@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url(https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular);
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Salsa&display=swap');
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
  justify-content: center;
}

.contingent-container {
  min-height: 100vh;
  background-image: url('../assets/contingent.jpg'); 
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  text-align: center;
}
.text{
  padding-left: 0;
  font-family: 'Proxima Nova Condensed', sans-serif;
  font-size: 1.5rem;
}

.contingent-title {
  font-size: 68px;
  padding: 10px 40px;
  text-shadow: 
        0 0 5px rgba(255, 255, 255, 0.7),
        0 0 10px rgba(255, 255, 255, 0.5),
        0 0 15px rgba(255, 255, 255, 0.3);
  margin-top: 0;
  text-align: left;
}

.contingent-grid {
  display: flex;
  justify-content: space-around;
  margin-top: 80px;
  padding: 0 20px;
}

.contingent-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 30%;
}

.contingent-img {
  width: 100%;
  border-radius: 15px;
}

.contingent-h2 {
  font-size: 50px;
  margin: 20px 0 10px;
  font-family: 'Proxima Nova Condensed', sans-serif;
}

.contingent-p {
  font-size: 36px;
  text-align: left;
  margin-right: 50px;
  color: #fff;
  font-family: 'Proxima Nova Condensed', sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  
}
@media (max-width: 1200px) and (min-width: 850px){
  .contingent-title{
    font-size: 4rem;
  }
  .contingent-container {
    padding-top: 20px;
    text-align: center;
  }

  .contingent-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0 10px;
  }

  .contingent-item {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 80px;
  }

  .contingent-img {
    width: 50%; 
    margin-right: 20px;
  }
  .contingent-h2 {
    font-size: 3rem;
    width: 50%;
    text-align: left;
  }

  .text {
    padding-left: 0; 
    margin-top: 20px;
  }

  .contingent-p {
    font-size: 35px;
    margin: 0 auto; 
    margin-top: -20px;
    padding-bottom: 35px;
    text-align: center;
  }
}
@media (max-width: 850px) and (min-width: 480px){
  .contingent-title{
    font-size: 3rem;
  }
  .contingent-container {
    padding-top: 20px;
    text-align: center;
  }

  .contingent-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0 10px;
  }

  .contingent-item {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 80px;
  }

  .contingent-img {
    width: 50%; 
    margin-right: 20px;
  }
  .contingent-h2 {
    font-size: 2rem;
    width: 50%;
    text-align: left;
  }

  .text {
    padding-left: 0; 
    margin-top: 20px;
  }

  .contingent-p {
    font-size: 24px;
    margin: 0 auto; 
    margin-top: -20px;
    padding-bottom: 35px;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .contingent-title{
    font-size: 3rem;
  }
  .contingent-container {
    padding-top: 20px;
    text-align: center;
  }

  .contingent-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0 10px;
  }

  .contingent-item {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 80px;
  }

  .contingent-img {
    width: 50%; 
    margin-right: 20px;
  }
  .contingent-h2 {
    font-size: 1.5rem;
    width: 50%;
    text-align: left;
  }

  .text {
    padding-left: 0; 
    margin-top: 20px;
  }

  .contingent-p {
    font-size: 24px;
    margin: 0 auto; 
    text-align: left;
  }
}
