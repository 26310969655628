.gaming-section {
    background-color: black;
}

.gaming-head {
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
    padding-top: 100px;
}

.g-box {
    width: 100px;
    margin: 5px;
}

.gaming-content {
    display: flex;
    padding: 100px;
    justify-content: space-around;
    color: white;
    text-align: center;
    align-items: center;
}

.gaming-h2 {
    font-size: 35px;
}
.gaming-content p {
    color: white;
    font-size: 25px;
    text-align: center;
}


@media (min-width:320px) and (max-width:480px) {
    .gaming-head {
        justify-content: center;
        flex-wrap: nowrap;
        margin-right: 0px;
        padding-top: 50px;
    }

    .g-box {
        width: 50px;
        margin: 0px;
    }

    .gaming-content {
        flex-direction: column;
    }

    .gaming-content p {
        width: 150%;
        font-size: 18px;
        margin-left:-50px;
    }
    .gaming-h2 {
        font-size: 22px;
    }
}