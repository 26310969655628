@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url(https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular);
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
}

.exploreContainer {
  min-height: 100vh;
  background-color: #19183e;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px; 
  position: relative;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  font-family: "League Gothic", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.upper {
  height: 20px;
  width: 100%;
  background-image: linear-gradient(90deg, #832eff, #fe4ff2);
  z-index: 100;
  top: 0;
  left: 0;
  position: absolute;
}

.explore-background-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45vw;
  color: #f0e706; 
  z-index: 1;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  pointer-events: none;
  padding-right: 150px;
  opacity: 0.3;
  letter-spacing: 10px;
  text-shadow: 
  0 0 5px #f0e706,
    0 0 10px #f0e706,
    0 0 15px #f0e706,
    0 0 20px #d4cb04,
    0 0 30px #d4cb04;
}





.overlay {
  text-align: left;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  max-height: 570px;
  padding: 0 5px;
  position: relative;
  z-index: 2;
}

.explore-title {
  font-size: 66px;
  margin-bottom: 10%;
  margin-left: 3%;
  margin-top: -2%;
  padding-left: 20px;
  letter-spacing: 0px;
  font-family: "Trend Sans One Regular";
}


 a {
  color: inherit;
  text-decoration: none; 
}

a:hover {
  color: inherit; 
}


.images-container {
  display: flex;
  justify-content: space-around;
  margin-top: -80px;
  width: 100%;
  height: auto;
}

.image-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.image-heading {
  margin-bottom: 10px; 
  color: white;
  font-family: "Trend Sans One Regular";;
  font-size: 30px;
}

.explore-image {
  width: 16vw;
  height: 45vh;
  border-radius: 30px;
  padding-left: 25px;

}
.ncaimg{
  height: 41vh;
  width: 17vw;
  padding-left: 0px;
  
  margin-right: -50px;
}
@media (max-width: 1200px) and (min-width: 850px) {
  .explore-image {
    width: 450px; 
    height: 550px;
    border-radius: 10px;
    
  }
  .explore-title{
    font-size: 5.5rem;
  }
  .images-container{
    overflow-x: auto;
    margin-top: 15%;
  }
  .ncaimg{
    height: 500px;
    width: 450px;
  }
  .image-heading{
    font-size: 3.5rem;
  }
  .images-container::-webkit-scrollbar {
    display: none; 
  }
}

@media (max-width: 850px) and (min-width: 480px) {
  .explore-image {
    width: 400px; 
    height: 500px;
    border-radius: 10px;
    
  }
  .explore-background-text{
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }
  .images-container{
    overflow-x: auto;
    margin-top: 15%;
  }
  .ncaimg{
    height: 450px;
    width: 420px;
  }
  .image-heading{
    font-size: 3rem;
  }
  .images-container::-webkit-scrollbar {
    display: none; 
  }
}

@media (max-width: 480px) {
  .exploreContainer{
    align-items: center;
    justify-content: center;
   min-height:10vh;
   padding: 0;
   height: 60vh;
  }
  .images-container {
    display: flex;
    flex-wrap: nowrap; 
    justify-content: flex-start; 
    overflow-x: auto; 
    scroll-behavior: smooth; 
    margin-top: 0vh;
    margin-bottom: 120vh;
    
    -webkit-overflow-scrolling: touch; 
  }

  .images-container::-webkit-scrollbar {
    display: none; 
  }

  .image-item {
    flex: 0 0 auto; 
    margin-right: 15px; 
  }

  .explore-image {
    width: 200px; 
    height: 250px;
    border-radius: 10px;
  }

  .ncaimg {
    height: 235px;
    width: 220px;
  }
  .incimg{
    height: 260px;
  }

  .explore-title {
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
    
    margin: 0;
    padding: 0;
    margin-top: 3vh;
  }

  .image-heading {
    font-size: 18px;
  }

  .explore-background-text {
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }
}