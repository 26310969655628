@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url(https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular);
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
  justify-content: center;
}

.ncaContainer {
  min-height: 100vh;
  background-image: url('../assets/ncabg.jpg');
  color: white;
  display: flex;
  flex-direction: column;
 
  padding-top: 100px; 
  position: relative;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  text-align: left;
}
.container{
  justify-content: space-around;
  display: flex;
  padding-top: 5%;
  text-align: center;
  font-family: 'Proxima Nova Condensed', sans-serif;
  font-weight: bold;
  font-size: 38px;
  text-decoration: underline;
  gap: .5rem;
  box-sizing: border-box;
}
.nca-image{
  width: 400px;
  height: 250px;
  max-width: 500px;
}
.nca-p{
  text-align: center;
  flex: 1;
}
.nca-title{
  font-size: 68px;
  padding: 10px 40px;
  text-shadow: 
        0 0 5px rgba(255, 255, 255, 0.7),
        0 0 10px rgba(255, 255, 255, 0.5),
        0 0 15px rgba(255, 255, 255, 0.3);
  margin-top: 0;   

}
@media (max-width: 1200px) and (min-width: 850px){
  .container{
    flex-direction: column;
    align-items: center;
  }
  .nca-image{
    width: 400px;
    height: 250px;
  }
  .nca-p{
    font-size: 2.5rem;
    
  }
  .image-text{
    margin-top: 2vh;
  }
  .nca-title{
    font-size: 7rem;
    
    text-align: center;
  }
}
@media (max-width: 850px) and (min-width: 480px){
  .container{
    flex-direction: column;
    align-items: center;
  }
  .nca-image{
    width: 320px;
    height: 200px;
  }
  .nca-p{
    font-size: 2rem;
    
  }
  .image-text{
    margin-top: 2vh;
  }
  .nca-title{
    font-size: 5rem;
    
    text-align: center;
  }
}
@media (max-width: 480px) {
  .container{
    flex-direction: column;
  }
  .nca-image{
    width: 200px;
    height: 125px;
  }
  .nca-p{
    font-size: 1.5rem;
    
  }
  .image-text{
    margin-top: 2vh;
  }
  .nca-title{
    font-size: 3rem;
    margin-left: 25%;
  }
}


