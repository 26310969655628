.writing-section {
    position: relative;
    height: auto;
    overflow: hidden;
}

.writing-bg {
    position: absolute;
    background-image: url('../assets/writingclub-bg.png');
    opacity: 0.3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.wc-content {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-row: 1fr;
    padding: 60px;
}

.wc-text h1 {
    grid-area: 1/1/2/2;
    color: #25201c;
    font-size: 80px;
    letter-spacing: 5px;
    margin-left: 50px;
}

.wc-text p {
    color: #25201c;
    text-align: left;
    font-size: 25px;
    margin-left: 5%;
    margin-right: 5%;
}

.wc-img {
    grid-area: 1/2/2/3;
    padding-top: 35%;
    display: flex;
    height: 400px;
}

@media (min-width:320px) and (max-width:640px) {
    .wc-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .wc-text h1 {
        font-size: 30px;
        text-align: center;
        margin-left: 0px;
    }

    .wc-text p {
        text-align: center;

        font-size: 18px;
        width: 90%;
        margin-left: 10px;
        margin-bottom:-50px;
    }
    .wc-img{
        flex-direction: column;
        width: 300px;
        height: auto;
    }
}