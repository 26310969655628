.comedy-section {
    height: auto;
    background-color: black;
    margin-top: -70px;
}

.main-content {
    display: flex;
    align-items: center;
    margin-top: -100px;
}

.text-comedy {
    width: 900px;
    font-size: 25px;
    color: white;
    margin-right: 120px;
}



.man-img {
    margin-left: 150px;
}

.comedy-h1 {
    text-align: center;
    padding-top: 100px;
    font-size: 100px;
    padding: 50px;
    color: yellow;
    font-style: italic;
}

@media (min-width:320px) and (max-width:480px) {
    .comedy-h1 {
        font-size: 43px;
    }

    .main-content {
        flex-direction: column;
    }

    .man-img {
        margin-left: -5%;
        width: 250px;
        margin-top: 15%;
    }

    .text .comedy-p {
        width: 35%;
        font-size: 18px;
        margin-left: 300px;
    }
    .text-comedy {
        width: 300px;
        text-align: center;
        margin-right: 0px;
        font-size: 18px;
    }
}
