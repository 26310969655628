@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url(https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular);
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Salsa&display=swap');
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
  justify-content: center;
}

.goeson-container {
  min-height: 100vh;
  background-image: url('../assets/ncabg.jpg');
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: 150px; 
  position: relative;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  text-align: left;
}

.goeson-title{
  font-size: 68px;
  padding: 10px 40px;
  text-shadow: 
        0 0 5px rgba(255, 255, 255, 0.7),
        0 0 10px rgba(255, 255, 255, 0.5),
        0 0 15px rgba(255, 255, 255, 0.3);
  margin-top: 0;   
}
.goeson-items {
  display: flex;
  justify-content: space-around;
  padding: 40px;
  text-align: left;
}

.goeson-item {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  text-align: left;
  width: 300px;
}

.goeson-img {
  width: 250px;
  margin-bottom: 10px;
  margin-top: 100px;
}

.goeson-h2 {
  font-size: 35px;
  margin: 10px 0;
  text-align: left;
  margin: 0;
  font-weight: bold;
  font-family: 'Proxima Nova Condensed', sans-serif;
}

.goeson-p {
  font-size: 25px;
  margin-left: -4vw;
  text-align: left;
  line-height: 1.5;
  width: 120%;
  margin-top: 1vh;
  color: white;
  font-family: 'Proxima Nova Condensed', sans-serif;
  font-weight: bold;
}
@media (max-width: 1200px) and (min-width: 850px) {
  .goeson-container{
    padding-top: 60px;
  }
  .goeson-title{
    font-size: 34px;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: -50px;
  }
  .goeson-items {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0;
    padding-bottom: 80px;
  }

  .goeson-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    text-align: left;
    width: 100%;
    margin-bottom: 0px; 
  }

 
  .goeson-img {
    width: 250px; 
    margin-bottom: 10px;
  }

  .goeson-h2 {
    font-size: 3rem;
    margin: 0px 50px;
    text-align: inherit; 
  }

  .goeson-p {
    font-size: 2.5rem;
    line-height: 1;
    width: 80%;
    margin: 0;
    margin-top: 1vh;
  }
  .goeson-title{
    font-size: 4.5rem;
  }
}
@media (max-width: 850px) and (min-width: 480px){
  .goeson-container{
    padding-top: 60px;
  }
  .goeson-title{
    font-size: 34px;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: -50px;
  }
  .goeson-items {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0;
    padding-bottom: 80px;
  }

  .goeson-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    text-align: left;
    width: 100%;
    margin-bottom: -60px; 
  }

 
  .goeson-img {
    width: 210px; 
    margin-bottom: 10px;
  }

  .goeson-h2 {
    font-size: 2.5rem;
    margin: 0px 50px;
    text-align: inherit; 

  }

  .goeson-p {
    font-size: 2rem;
    line-height: 1;
    margin: 0;
    width: 70%;
    margin-top: 0vh;
  }
  .goeson-title{
    font-size: 4rem;
  }

}

@media (max-width: 480px) {
  .goeson-container{
    padding-top: 60px;
  }
  .goeson-title{
    font-size: 34px;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: -50px;
  }
  .goeson-items {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0;
    padding-bottom: 80px;
  }

  .goeson-item {
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: left;
    width: 100%;
    margin-bottom: -60px; 
  }

 
  .goeson-img {
    width: 180px; 
    margin-bottom: 10px;
  }

  .goeson-h2 {
    font-size: 28px;
    margin: 0px 50px;
    text-align: inherit; 
  }

  .goeson-p {
    font-size: 20px;
    line-height: 1;
    margin: 0;
    margin-top: 0vh;
    width: 70%;
  }
}