.classical-section {
  position: relative;
  height: auto;
  overflow: hidden;
}

.background-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(8px);
  z-index: -1;
}

.content {
  z-index: 1;
}

.content-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.text-box {
  background-image: url("../assets/invitationbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 400px;
  height: 100%;
  margin-bottom: 15px;
}

.classical-text {
  font-size: 20px;
  color: hwb(0 1% 65%);
  text-align: center;
  padding: 50px;
  padding-left: 60px;


}

.classical-h1 {
  text-align: center;
  font-size: 90px;
  padding: 10px;
  color: yellow;
  font-style: italic;
  margin: 50px;
}
.classical-team{
  width: 650px;
}

@media (min-width:320px) and (max-width:640px) {
  .classical-h1 {
    font-size: 30px;
  }

  .content-section {
    flex-direction: column;
  }
  .classical-team{
    width: 300px;
  }
}
.clubs-footer {
  background-color: black;
  height: auto;
  color:white;
  align-items: center;
  display: flex;
  justify-content: center;
}

.clubs-footer ul {
  display: flex;
  list-style-type: none;
}

.clubs-footer ul li {
  margin-right: 50px;
}