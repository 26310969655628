.quiz-section {
    background-color: black;
    margin-top: -70px;
}

.quiz-content {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr;
    grid-template-rows: 1fr 1fr;
    padding: 0px 50px 50px 50px;
}

.q-img1 {
    grid-area: 1/1/3/2;
    padding-left: 60px;
}

.q-img2 {
    grid-area: 1/3/2/4;

}

.q-img3 {
    grid-area: 2/3/3/4;

}

.q-text {
    grid-area: 1/2/3/3;
    background-color: white;
    border-radius: 5%;
    margin: 10px;
}

.q-text p {
    color: #dab624;
    text-align: center;
    font-size: 25px;
}

.quiz-h1 {
    text-align: center;
    font-size: 100px;
    color: yellow;
    font-style: italic;
}

@media (min-width:320px) and (max-width:640px) {
    .quiz-h1 {
        font-size: 30px;
        padding-top: 50px;
        margin-bottom: 30px;
    }

    .quiz-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .q-img1 img {
        width: 300px;
    }
    .q-img2 img {
        width: 300px;
    }
    .q-img3 img{
        width: 300px;
    }
    .q-text {
        width: 110%;
    }

    .q-text p {
        font-size: 18px;
        margin-left: 30px;
    }
}