@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url('https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular');
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.cdnfonts.com/css/coco-gothic');

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
}

.exploreclubsContainer {
  min-height: 100vh;
  background-color: #19183e;
  color: #f8e3c4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 100px; 
  position: relative;
  overflow: visible;
  font-family: "League Gothic", sans-serif;
}

.heading-club {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  paint-order: stroke fill;
  -webkit-text-stroke: 8px black;
  font-size: 5rem;
  font-family: "Trend Sans One Regular";
  color: #f8e3c4;
  z-index: 3;
  margin: 0;
}

.exploreclubs-background-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45vw;
  color: #ae267a;
  z-index: 1;
  white-space: nowrap;
  text-align: center;
  pointer-events: none;
  opacity: 0.3;
  letter-spacing: 10px;
  text-shadow: 
    0 0 1px #ae267a,
    0 0 2px #ae267a,
    0 0 3px #ae267a,
    0 0 4px #8b1b63;
}

.explore-clubs-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 80px;
  justify-content: center;
  padding: 40px;
  padding-left: 100px;
  margin: 80px;
  z-index: 2;
  position: relative;
  width: 100%;
  
  grid-auto-rows: min-content;
}

.club-container {
  display: flex;
  align-items: center;
  background-color: #fffbe0;
  margin: 2px;
  border-radius: 4px;
  border: 2px solid black;
  border-width: 4px;
  padding: 40px;
  padding-right: 30px;
  max-width: 200px;
  height: 95px;
  color: black;
  text-align: center;
  font-family: 'Coco Gothic', sans-serif;
  font-weight: bold;
  z-index: 3;
  position: relative;
  padding-left: 60px;
  box-sizing: border-box; 
  cursor: pointer;
}

.exploreclubs-image {
  width: 250px;
  height: 160px;
  position: absolute;
  left: -55px;
  bottom:-25px;
}

.exploreclubs-p{
  margin: 0;
  font-size: 20px;
  color: black;
}



.explore-clubs-container > :nth-child(13) {
  grid-column: 2 / 3;
  margin-left: -200px;
}

.explore-clubs-container > :nth-child(14) {
  grid-column: 3 / 4;
  margin-left: -200px;
}

.explore-clubs-container > :nth-child(15) {
  grid-column: 4 / 5;
  margin-left: -200px;
}


.explore-clubs-container > :nth-child(16) {
  display: none;
}
@media (max-width: 1200px) and (min-width: 850px){
  .exploreclubsContainer{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
  }
  .heading-club{
    font-size: 5.5rem;
  }
  
  .explore-clubs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 50px;
    padding: 10px;
    margin-left: 60px;
    box-sizing: border-box;
    margin-right: 20px;
    
  }

  .club-container {
    background-color: #fffbe0;
    border: 2px solid black;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-left: -10px;
    box-sizing: border-box; 
    max-width: 300px;
    height: 150px;
    
  }
  .exploreclubs-p{
    font-size: 2rem;
  }
  .exploreclubs-background-text{
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }

  .exploreclubs-image 
    {
    width: 160px; 
    height: 120px;
    position: relative;
    left: 10px; 
    margin-top: -40px;
  }

  .explore-clubs-container > .club-container:nth-last-child(-n+3) {
    grid-column: span 1;
    max-width: 300px; 
    padding: 5px;
  }
  .explore-clubs-container > :nth-child(13) {
    
    margin-left: -10px;
  }
  
  .explore-clubs-container > :nth-child(14) {
    
    margin-left: -10px;
  }
  
  .explore-clubs-container > :nth-child(15) {
    
    margin-left: -10px;
  }
}
@media (max-width: 850px) and (min-width: 480px){
  .exploreclubsContainer{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
  }
  .heading-club{
    font-size: 5rem;
  }
  
  .explore-clubs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 30px;
    padding: 10px;
    margin-left: 50px;
    box-sizing: border-box;
    margin-right: 20px;
    margin-bottom: -20px;
  }

  .club-container {
    background-color: #fffbe0;
    border: 2px solid black;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-left: -10px;
    box-sizing: border-box;
    margin-bottom: 35px; 
  }
  .exploreclubs-background-text{
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }

  .exploreclubs-image 
    {
    width: 150px; 
    height: 120px;
    position: relative;
    left: 10px; 
    margin-top: -90px;
  }
  .exploreclubs-p{
    bottom: 0;
    margin-top: 20px;
  }

  .explore-clubs-container > .club-container:nth-last-child(-n+3) {
    grid-column: span 1;
    max-width: 200px; 
    padding: 5px;
  }
  .explore-clubs-container > :nth-child(13) {
    
    margin-left: -10px;
  }
  
  .explore-clubs-container > :nth-child(14) {
    
    margin-left: -10px;
  }
  
  .explore-clubs-container > :nth-child(15) {
    
    margin-left: -10px;
  }
}
@media (max-width: 480px) and (min-width:365px){
  .exploreclubsContainer{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
  }
  .explore-clubs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 30px;
    padding: 10px;
    margin-left: 10px;
    box-sizing: border-box;
    margin-right: 20px;
  }

  .club-container {
    background-color: #fffbe0;
    border: 2px solid black;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-left: -10px;
    box-sizing: border-box; 
  }
  .exploreclubs-background-text{
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }

  .exploreclubs-image 
    {
    width: 85px; 
    height: 60px;
    position: relative;
    bottom: 35px;
    left: 10px; 
  }
  .exploreclubs-p{
    margin-top: -3vh;
  }

  .explore-clubs-container > .club-container:nth-last-child(-n+3) {
    grid-column: span 1;
    max-width: 110px; 
    padding: 5px;
  }
  .explore-clubs-container > :nth-child(13) {
    
    margin-left: -10px;
  }
  
  .explore-clubs-container > :nth-child(14) {
    
    margin-left: -10px;
  }
  
  .explore-clubs-container > :nth-child(15) {
    
    margin-left: -10px;
  }
}
@media (max-width:365px){
  .exploreclubsContainer{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
  }
  .explore-clubs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 20px;
    padding: 10px;
    margin-left: 5px;
    box-sizing: border-box;
    margin-right: 20px;
  }

  .club-container {
    background-color: #fffbe0;
    border: 2px solid black;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-left: -10px;
    box-sizing: border-box; 
  }
  .exploreclubs-background-text{
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }

  .exploreclubs-image 
    {
    width: 85px; 
    height: 60px;
    position: relative;
    bottom: 35px;
    left: 10px; 
  }
  .exploreclubs-p{
    margin-top: -3vh;
  }

  .explore-clubs-container > .club-container:nth-last-child(-n+3) {
    grid-column: span 1;
    max-width: 110px; 
    padding: 5px;
  }
  .explore-clubs-container > :nth-child(13) {
    
    margin-left: -10px;
  }
  
  .explore-clubs-container > :nth-child(14) {
    
    margin-left: -10px;
  }
  
  .explore-clubs-container > :nth-child(15) {
    
    margin-left: -10px;
  }
}