.choreo-section {
    background-color: #bb0707;
    height: auto;
    background-size: cover;
    margin-top: -100px;
}

.choreo-head {
    font-style: italic;
    font-size: 150px;
    padding: 50px;
    padding-bottom: 0%;
    color: yellow;
    text-align: center;
}

.choreo-text {
    color: white;
    font-size: 25px;
    margin-left: auto;
    padding: 20px;
    margin-right: 10%
}

.img-block {
    display: flex;
    margin: 30px;
    margin-right: 5%;
}

.choreo-content {
    display: flex;
    align-items: center;

}
.clubs-footer{
    background-color: black;
    height: fit-content;
    color: white;
    margin-top: -20px;
    margin-bottom: -20px;
    display: flex;
    justify-content:center;
}
.clubs-footer ul{
    display: flex;
    list-style-type: none;
}
.clubs-footer ul li{
    margin-right: 50px;
}
@media (min-width: 320px) and (max-width: 640px) {
    .choreo-section {
        margin-top: -40px;
    }

    .choreo-content {
        flex-direction: column;
        align-items: center;
    }

    .img-block {
        flex-direction: column;
        margin-right: 8%;
    }

    .choreo-head {
        font-size: 45px;
    }

    .choreo-text {
        font-size: 18px;
        margin-left: 10%;
    }
    .clubs-footer{
        flex-direction: column;
    }
}
