@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url(https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular);
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.cdnfonts.com/css/beautiful-people-personal-use');
@import url('https://fonts.cdnfonts.com/css/twister-2');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Salsa&display=swap');
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  justify-content: center;
}

.chronicles-container {
  min-height: 100vh;
  background-image: url('../assets/ncabg.jpg');
  color: white;
  display: flex;
  flex-direction: column;
  background-position: center;
  align-items: center;
  padding-top: 150px;
  overflow: visible;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
}

.chronicles-heading {
  text-align: center;
  margin-bottom: -245px;
  align-items: center;

}

.chronicle-title {
  font-family: 'Twister', sans-serif;
  font-size: 6rem;
  margin: 0;
  align-items: center;
  text-align: center;
  margin-top: -130px;
  
}

.chronicle-subtitle {
  font-family: cursive;
  font-size: 1.5rem;
  margin-top: 50px;
  color: #eee;
  text-align: center;
  padding-left: 20%;
  width: 300px;
  z-index: 2;
}

.chronicles-grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;
  gap: 0px;
}

.chronicle-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin-bottom: 100px;
  
}

.middle {
  margin-top: 150px; 
}

.chronicle-img {
  width: 110%;
  gap: 50px;
  height: auto;
  border-radius: 15px;
  z-index: 1;
 margin: 20px;
}

.chronicle-caption {
  margin-top: 10px;
  font-family: 'Trend Sans One Regular', sans-serif;
  font-size: 1.2rem;
  color: #ff0066;
  text-align: center;
}

.read-more {
  margin-top: 30px;
}

.read-more-btn {
  background-color: #e2c6d0;
  color: #333;
  font-family: 'League Gothic', sans-serif;
  font-size: 1.5rem;
  padding: 15px 0px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  width: 500px;
  align-items:center;
  justify-content: center;
  margin-bottom: 150px;
  
}

.read-more-btn::after {
  content: '→';
  margin-left: 10px;
}
@media (max-width: 1200px) and (min-width: 850px){
  .chronicle-title{
    font-size: 7rem;
    margin-top: -20px;
  }
    .chronicle-subtitle{
      font-size: 40px;
      padding: 0;
      padding-left: 160px;
    }
    .chronicles-grid{
      margin-top: 5vh;
    }
    .chronicle-img{
      width: 320px;
      margin: 120px;
    }
    .read-more-btn{
      font-size: 2rem;
    }
  }
@media (max-width: 850px) and (min-width: 480px){
  .chronicle-title{
    font-size: 4.5rem;
    margin-top: -20px;
  }
    .chronicle-subtitle{
      font-size: 30px;
      padding: 0;
      padding-left: 50px;
    }
    .chronicles-grid{
      margin-top: 5vh;
    }
    .chronicle-img{
      width: 210px;
      margin: 75px;
    }
    .read-more-btn{
      font-size: 2rem;
    }
  }
@media (max-width: 480px){
.chronicle-title{
  font-size: 3.5rem;
  margin-top: -20px;
}
  .chronicle-subtitle{
    font-size: 25px;
    padding: 0;
  }
  .chronicles-grid{
    margin-top: 25vh;
  }
  .chronicle-img{
    width: 140px;
    margin: 50px;
  }
}