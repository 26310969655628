@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url('https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular');
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
}

.clubsContainer {
  min-height: 100vh;
  background-color: #19183e;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 100px; 
  position: relative;
  overflow: visible;
  font-family: "League Gothic", sans-serif;
}

.clubs-background-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45vw;
  color: #ae267a; 
  z-index: 1;
  white-space: nowrap;
  text-align: center;
  pointer-events: none;
  opacity: 0.3;
  letter-spacing: 10px;
  text-shadow: 
    0 0 1px #ae267a,
    0 0 2px #ae267a,
    0 0 3px #ae267a,
    0 0 4px #8b1b63;
}

.left-container,
.right-container {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  overflow: visible;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  padding-right: 7%;
  text-align: left;

}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  text-align: left;
  font-family: 'Proxima Nova Condensed', sans-serif;
  padding-top: 0;
  padding-bottom: 8%;
  
  

}
.left-heading{
  font-size: 65px;
  z-index: 3;
  margin-bottom: 3%;
  margin-top: -50px;
  font-family: "Trend Sans One Regular";
  text-shadow: 
  0 0 3px rgba(255, 255, 255, 0.6),
  0 0 6px rgba(255, 255, 255, 0.5),
  0 0 9px rgba(255, 255, 255, 0.4),
  0 0 10px rgba(255, 255, 255, 0.3);
}

.right-heading {
  margin: 10px 0;
  padding: 0;
}

.right-para {
  margin-bottom: 20px;
}
.left-image {
 width: 600px;
  height: 367px; 
  max-width: 100%; 
  border-radius: 75px; 
  object-fit: cover;
  z-index: 3;
}
.left-para{
  font-size: 35px;
  z-index: 3;
  font-family: 'Proxima Nova Condensed', sans-serif;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 2%;
  margin-bottom: 20px;
}
.right-para{
  font-size: 26px;
}
.clubexploreButton{
  z-index: 3;
  background: linear-gradient(to right, #823eff, #fe4ff2);
  color: white;
  border: none;
  
  font-weight: bold;
  font-family: 'Proxima Nova Condensed', sans-serif;
  letter-spacing: 0.5px;                                         
  text-decoration: underline;
  padding: 10px 30px;
  font-size: 27px;
  border-radius: 50px;
  cursor: pointer;
  bottom: 0;
  margin-bottom: 40px;
  position: absolute;
}
.right-heading{
  font-size: 26px;
  color: #ff4ff3;
  font-weight: bold;
}
button a {
  color: inherit;
  text-decoration: none; 
}

button a:hover {
  color: inherit; 
}
@media (max-width: 1200px) and (min-width: 850px) {
  .clubsContainer {
    flex-direction: column; 
    padding: 20px;
    justify-content: flex-start; 
  }

  .clubs-background-text {
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }

  
  .left-container {
    order: 1;
    text-align: justify; 
    padding-left: 0;
    padding-right: 0;
  }
  
  .left-heading {
    font-size: 4.5rem; 
    margin-top: 20px;
  }

  .left-para {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

 
  .left-image {
    width: 40%; 
    height: auto;
    max-width: 100%;
    border-radius: 20px; 
    display: block;
    margin: 0 auto; 
  }

  
  .right-container {
    order: 2; 
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
    text-align: justify;
  }
  .right-heading {
    margin-bottom: 0;
  }

  .right-heading {
    font-size: 2.5rem;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .right-para {
    font-size: 2rem; 
    margin-bottom: 10px;
  }

  
  .clubexploreButton {
    order: 3; 
    position: relative;
    margin: 30px auto; 
    display: block;
    padding: 20px 20px;
    font-size: 2rem;
    bottom: 0;
  }
}

@media (max-width: 850px) and (min-width: 480px) {
  .clubsContainer {
    flex-direction: column; 
    padding: 20px;
    justify-content: flex-start; 
  }

  .clubs-background-text {
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }

  
  .left-container {
    order: 1;
    text-align: justify; 
    padding-left: 0;
    padding-right: 0;
  }
  
  .left-heading {
    font-size: 4rem; 
    margin-top: 20px;
  }

  .left-para {
    font-size: 2rem;
    margin-bottom: 10px;
  }

 
  .left-image {
    width: 40%; 
    height: auto;
    max-width: 100%;
    border-radius: 20px; 
    display: block;
    margin: 0 auto; 
  }

  
  .right-container {
    order: 2; 
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
    text-align: justify;
  }
  .right-heading {
    margin-bottom: 0;
  }

  .right-heading {
    font-size: 2rem;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .right-para {
    font-size: 1.5rem; 
    margin-bottom: 10px;
  }

  
  .clubexploreButton {
    order: 3; 
    position: relative;
    margin: 30px auto; 
    display: block;
    padding: 20px 20px;
    font-size: 1.5rem;
    bottom: 0;
  }
}

@media (max-width: 480px) {
  .clubsContainer {
    flex-direction: column; 
    padding: 20px;
    justify-content: flex-start; 
  }

  .clubs-background-text {
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }

  
  .left-container {
    order: 1;
    text-align: justify; 
    padding-left: 0;
    padding-right: 0;
  }
  .right-container p{
    margin-bottom: 2vh;
  }

  .left-heading {
    font-size: 40px; 
    margin-top: 20px;
  }

  .left-para {
    font-size: 20px;
    margin-bottom: 10px;
  }

 
  .left-image {
    width: 70%; 
    height: auto;
    max-width: 100%;
    border-radius: 20px; 
    display: block;
    margin: 0 auto; 
  }

  
  .right-container {
    order: 2; 
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
    text-align: justify;
  }
  .right-heading {
    margin-bottom: 0;
  }

  .right-heading {
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .right-para {
    font-size: 18px; 
    margin-bottom: 10px;
  }

  
  .clubexploreButton {
    order: 3; 
    position: relative;
    margin: 20px auto; 
    display: block;
    padding: 10px 10px;
    font-size: 1rem;
    bottom: 0;
  }
}
