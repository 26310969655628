.inf-section {
    background-color: black;
    margin-top: -70px;
}

.inf-content {
    display: grid;
    grid-template-columns: 1fr 2fr 1.25fr 1.5fr;
    grid-template-rows: 1fr 1fr;
    padding: 0px 50px 50px 50px;
}

.Inf-img1 {
    grid-area: 1/1/3/2;
}

.Inf-img2 {
    grid-area: 2/2/3/3;
}

.Inf-img3 {
    grid-area: 2/3/3/4;

}


.Inf-text {
    grid-area: 1/2/2/5;

}

.Inf-text p {
    color:#fad5e5 ;
    text-align: center;
    font-size: 25px;
    margin-left: 5%;
    margin-right:5%
}

.inf-h1 {
    text-align: center;
    font-size: 100px;
    color: yellow;
    font-style: italic;
}

@media (min-width:320px) and (max-width:640px) {
    .inf-h1 {
        font-size: 30px;
        padding-top: 50px;
    }

    .inf-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Inf-text p {


        font-size: 18px;
        
    }
    .Inf-text{
        flex-direction: column;
    }
}