.dnv-section {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 1.2fr 1fr;
}

.dnv-head {
    grid-area: 1/1/2/2;
    background-color: #0cc0df;
    border-top: 100px solid white;
    border-left: 100px solid white;
}

.dnv-head img {
    margin-top: -95px;
    width: 800px;
    margin-left: 30px;
}

.dnv-img {
    grid-area: 1/2/2/3;
}

.dnv-img div {
    background-color: #0cc0df;
    border-left: 100px solid white;
    border-right: 100px solid white;
    padding-bottom: 20px;
}

.dnv-img div img {
    padding-left: 85px;

}

.dnv-img div p {
    font-size: 30px;
    margin-left: 70px;
    text-align: center;
    color: #002659;
}

.dnv-text {
    grid-area: 2/1/3/2;
    border-left: 100px solid #0cc0df;
}

.dnv-text p {
    font-size: 25px;
    color: black;
    margin: 50px;
    text-align: center;
}

.dnv-plain {
    grid-area: 2/2/3/3;
    background-color: #0cc0df;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (min-width:320px) and (max-width:640px) {
    .dnv-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #0cc0df;
    }

    .dnv-head img {
        width: 370px;
        margin-top: 0px;
        margin-left: 10px;
    }

    .dnv-img div {
        border: none;
    }

    .dnv-head {
        border: none;
    }

    .dnv-img div img {
        margin-top: 40px;
    }

    .dnv-text {
        border-left: 0px;
    }

    .dnv-text p {
        font-size: 18px;
        margin-top: -20px;
    }

    .dnv-img div p {
        font-size: 25px;
        margin: 30px;
    }
}