.finearts-section {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr;
}

.fine-img {
    grid-area: 1/1/2/2;
    background-color: #f3f6ea;
    padding: 100px;
}

.fine-text {
    grid-area: 1/2/2/3;
    background-color: #c54310;
    text-align: left;
}

.fine-text h1 {
    color: #f3f6ea;
    font-size: 120px;
    font-weight: bold;
    margin-left: 20px;
}

.fine-text p {
    color: #f3f6ea;
    font-size: 25px;
    text-align: left;
    margin-left: 70px;
}

@media (min-width:320px) and (max-width:640px) {
    .finearts-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .fine-text h1 {
        margin-top: 20px;
        font-size: 30px;
        text-align: center;
    }

    .fine-text p {
        font-size: 18px;
        text-align: center;
        margin: 30px;
    }
}