@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
.wg-section {
    background-color: #cbcbcb;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 70px;
    padding-bottom: 0px;
    height: auto;
}

.wg-head {
    grid-area: 1/1/2/2;
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    height: 240px;
}

.wg-head h1 {
    font-size: 80px;
    font-weight: bold;
    margin-left: 175px;
    line-height: 65px;
    margin-top: 20px;
}

.wg-img1 {
    grid-area: 1/2/3/3;
    margin: 10px;
    padding-left: 200px;
}

.wg-img2 {
    grid-area: 2/1/4/2;
    margin: 10px;
}

.wg-img2 img {
    height: 350px;
    margin-top: 50px;
    border: 20px solid #005401;
    border-radius: 20px;
    margin-left: 10%;
}

.wg-text {
    grid-area: 3/2/4/3;
    background-color: #005401;
    border-radius: 10px;
    margin: 10px;
    height: auto;
    margin-top: -100px;

}

.wg-text p {
    margin: 20px;
    color: white;
    font-weight: bold;
    font-size: 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

@media (min-width:320px) and (max-width:640px) {
    .wg-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .wg-head {
        height: auto;
    }

    .wg-head h1 {
        font-size: 50px;
        margin: 15px;
        line-height: 40px;
    }

    .wg-img1 img {
        width: 200px;
    }

    .wg-img1 {
        padding-left: 0px;
    }

    .wg-img2 {
        margin-top: -50px;
    }

    .wg-img2 img {
        height: 250px;
        margin-left: 0;
    }

    .wg-text {
        margin: 0px;
        width: 130%;
    }

    .wg-text p {
        width: 80%;
        margin: 30px;
        font-size: 18px;
    }
}