.fashion-section {
    background-color: #ede8d1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 50px;
}

.f-head {
    grid-area: 1/1/2/3;
    background-color: #4f1e0c;
    border-radius: 10px;
    margin: 20px;
    height: 250px;
}

.f-head h1 {
    color: #ede8d1;
    font-size: 100px;
    text-align: center;
    margin-top: 50px;
    margin-left: -20px;
}

.f-img {
    grid-area: 1/3/2/4;
    margin: 20px;
}

.f-img img {
    width: 300px;
    border: 15px solid #934713;
    border-radius: 10px;
}

.f-text {
    grid-area: 2/1/3/4;
    background-color: #da6220;
    border-radius: 10px;
    height: fit-content;

}

.f-text p {
    color: #ede8d1;
    font-weight: bold;
    margin: 50px;
    font-size: 22px;
}

@media (min-width:320px) and (max-width:640px) {
    .fashion-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .f-head h1 {
        font-size: 30px;
        margin: 20px;
    }

    .f-text {
        width: 110%;
        margin-top: 20px;
    }

    .f-text p {
        padding: 10px;
        font-size: 18px;
    }
    .f-head{
        height: 80px;
    }
}