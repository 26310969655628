@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url(https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular);
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rakkas&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
  
}

.homeContainer {
  min-height: 100vh;
  background-color: #19183e;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15vh; 
  position: relative;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  font-family: "League Gothic", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.upper {
  height: 20px;
  width: 100%;
  background-image: linear-gradient(90deg, #832eff, #fe4ff2);
  z-index: 100;
  top: 0;
  left: 0;
  position: absolute;
}

.home-background-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45vw;
  color: #ae267a; 
  z-index: 1;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  pointer-events: none;
  padding-right: 150px;
  opacity: 0.3;
  letter-spacing: 10px;
  text-shadow: 
  0 0 1px #ae267a,
  0 0 2px #ae267a,
  0 0 3px #ae267a,
  0 0 4px #8b1b63;
}

.triangle-image {
  position: absolute;
  top: 50%;
  right: 0; 
  transform: translateY(-50%);
  z-index: 2;
  max-width: 100%;  
  height: auto;
  margin-top: 60px;
  margin-right: -120px;
}

.texts {
  padding-top: 20px;
}

.overlay {
  text-align: left;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  max-height: 570px;
  padding: 0 5px;
  position: relative;
  z-index: 10;
}

.home-title {
  font-size: 150px;
  margin-bottom: 10px;
  padding-left: 20px;
  letter-spacing: 2px;
  font-family: 'Trend Sans One';
}

.subtitle {
  font-size: 36px;
  margin-bottom: 100px;
  margin-left: 20px;
  color: #ff4ff3;
  text-align: left;
  letter-spacing: .01px;
  font-family: "Rakkas", serif;
  font-weight: 400;
  font-style: normal;
}

.exploreButton {
  background: linear-gradient(to right, #823eff, #fe4ff2);
  color: white;
  border: none;
  padding: 15px 50px;
  font-weight: bold;
  font-family: 'Proxima Nova Condensed', sans-serif;
  letter-spacing: 0.5px;                                                                   
  text-decoration: underline;
  margin-left: 20px;
  font-size: 27px;
  border-radius: 50px;
  cursor: pointer;
  bottom: 0;
  left: 0;
  z-index: 10;
  position: absolute;
}

.exploreButton:hover {
  opacity: 80%;
}
button a {
  color: inherit;
  text-decoration: none; 
}

button a:hover {
  color: inherit; 
}


@media (max-width: 1200px) and (min-width: 850px) {
  html, body {
    overflow-x: hidden;
    overflow: hidden;
  }
  .homeContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
  }
  .overlay{
    z-index: 10;
  }
  .home-title {
    font-size: 7rem;
    margin-top: -50%;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    padding-left: 0;
  }

  .subtitle {
    font-size: 2rem;
    text-align: center;
    margin-top:-5%;
    margin-left: 1vw;
    padding-left: 0;
    width: 95%;
    font-family: "Playwrite CU", cursive;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  }

  .exploreButton {
    font-size: 2rem;
    padding: 20px 50px;
    margin-left: 40%;
    position: relative;
    margin-top: 35%;
    z-index: 10;
    pointer-events: auto;
    
  }

  .triangle-image {
    width: 100%;
    top: 47%;
    z-index: 4;
    right: 0%;
    margin-right: 0;
    position: absolute;
    pointer-events: none;
  }
  .overlay{
    z-index: 10;
  }

  .home-background-text {
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }
  .texts{
    top: 0;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 850px) and (min-width: 480px) {
  html, body {
    overflow-x: hidden;
    overflow: hidden;
    
  }
  .homeContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
    
  }
  .overlay{
    z-index: 10;
  }
  .home-title {
    font-size: 5rem;
    margin-top: -50%;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    padding-left: 0;
  }

  .subtitle {
    font-size: 1rem;
    text-align: center;
    margin-top: -5%;
    margin-left: 1vw;
    padding-left: 0;
    width: 95%;
    font-family: "Playwrite CU", cursive;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  }

  .exploreButton {
    font-size: 2rem;
    padding: 10px 30px;
    margin-left: 36%;
    position: relative;
    margin-top: 35%;
    z-index: 10;
    pointer-events: auto;
    
  }

  .triangle-image {
    width: 100%;
    top: 47%;
    z-index: 4;
    right: 0%;
    margin-right: 0;
    position: absolute;
    pointer-events: none;
  }
  .overlay{
    z-index: 10;
  }

  .home-background-text {
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }
  .texts{
    top: 0;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) and (min-width:365px){
  html, body {
    overflow-x: hidden;
    overflow: hidden;
    height: auto;
  }
  .homeContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
    padding-top: 10vh;
    min-height: 50vh;
    overflow-y: hidden;
  }
  .overlay{
    z-index: 10;
  }
  .home-title {
    font-size: 55px;
    margin-top: -35%;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    padding-left: 0;
  }

  .subtitle {
    font-size: 13px;
    text-align: center;
    margin-top: -10%;
    padding-left: 0;
    margin-left: 5vw;
    width: 90%;
    font-family: "Playwrite CU", cursive;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  }

  .exploreButton {
    font-size: 15px;
    padding: 10px 30px;
    margin-left: 33%;
    position: relative;
    margin-top: 35%;
    z-index: 10;
    pointer-events: auto;
    
  }

  .triangle-image {
    width: 100%;
    top: 52%;
    z-index: 4;
    right: 0%;
    margin-right: 0;
    position: absolute;
    pointer-events: none;
  }
  .overlay{
    z-index: 10;
  }

  .home-background-text {
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }
  .texts{
    top: 0;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width:365px){
  html, body {
    overflow-x: hidden;
    overflow: hidden;
    height: auto;
  }
  .homeContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
    padding-top: 10vh;
    min-height: 50vh;
    overflow-y: hidden;
  }
  .overlay{
    z-index: 10;
  }
  .home-title {
    font-size: 55px;
    margin-top: -30%;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    padding-left: 0;
  }

  .subtitle {
    font-size: 13px;
    text-align: center;
    margin-top: -10%;
    padding-left: 0;
    margin-left: 5vw;
    width: 90%;
    font-family: "Playwrite CU", cursive;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  }

  .exploreButton {
    font-size: 15px;
    padding: 10px 30px;
    margin-left: 33%;
    position: relative;
    margin-top: 35%;
    z-index: 10;
    pointer-events: auto;
    
  }

  .triangle-image {
    width: 100%;
    top: 52%;
    z-index: 4;
    right: 0%;
    margin-right: 0;
    position: absolute;
    pointer-events: none;
  }
  .overlay{
    z-index: 10;
  }

  .home-background-text {
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }
  .texts{
    top: 0;
    justify-content: center;
    align-items: center;
  }
}
