@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url(https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular);
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Salsa&display=swap');
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
  justify-content: center;
}

.nca2Container {
  min-height: 100vh;
  background-image: url('../assets/ncabg.jpg');
  color: white;
  display: flex;
  flex-direction: column;
  
  padding-top: 100px; 
  position: relative;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  text-align: left;
}

.nca2-title{
  font-size: 68px;
  padding: 10px 40px;
  text-shadow: 
        0 0 5px rgba(255, 255, 255, 0.7),
        0 0 10px rgba(255, 255, 255, 0.5),
        0 0 15px rgba(255, 255, 255, 0.3);
  margin-top: 0;   

}
.boxesContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 75px;
  margin-bottom: 40px;
  margin-right: 30px;
  margin-left: -5px;
}

.box {
  padding: 20px;
  margin-left: 30px;
  padding-left: 20px;
  color: white;
  font-size: 35px;
  text-align: center;
  border-radius: 8px;
  height: 120px;
  width: 250px;
  display: flex;
  text-align: center;
  font-family: "Salsa", cursive;
  font-weight: 400;
  font-style: normal;
  align-items: center;
  justify-content: center;
  z-index: 0;
}


.box-1 { background-color: black; }
.box-2 { background-color: #062639; }
.box-3 { background-color: #221c1b; }
.box-4 { background-color: #062639; }
.box-5 { background-color: #221c1b; }
.box-6 { background-color: black; }
.box-7 { background-color: #062639; }
.box-8 { background-color: black; }
.box-9 { background-color: #062639; }
.box-10 { background-color: #221c1b; }
.box-11 { background-color: black; }
.box-12 { background-color: #221c1b; }

.box-1 { color: #ff0085; }
.box-2 { color: white; }
.box-3 { color: #3e7253; }
.box-4 { color: white#ff0085; }
.box-5 { color: #dab624; }
.box-6 { color: #ff0085; }
.box-7 { color: white; }
.box-8 { color: #ff0085; }
.box-9 { color: white; }
.box-10 { color: #e27a18; }
.box-11 { color: #ff0085; }
.box-12 { color: #e27a18; }

@media (max-width: 1200px) and (min-width: 850px){
  .nca2Container{
    justify-content: center;
  }
  .boxesContainer {
    display: flex;
    flex-wrap: nowrap; 
    overflow-x: auto; 
    gap: 20px; 
    justify-content: flex-start; 
    padding: 10px;
    margin: 0; 
    scroll-behavior: smooth; 
  }

  .box {
    min-width: 300px;
    height: 260px; 
    text-align: center;
    justify-content: center;
    padding: 20px;
    font-size: 1.5rem;
    flex: 0 0 auto; 
    margin-left: auto;
    margin-right: auto;
    font-size: 3.5rem;
  }

  .nca2Container {
    padding: 20px; 
    text-align: center; 
  }


  .nca2-title {
    font-size: 7rem; 
    padding: 10px 20px;
    text-align: center;
  }

}
@media (max-width: 850px) and (min-width: 480px){
  .nca2Container{
    justify-content: center;
  }
  .boxesContainer {
    display: flex;
    flex-wrap: nowrap; 
    overflow-x: auto; 
    gap: 20px; 
    justify-content: flex-start; 
    padding: 10px;
    margin: 0; 
    scroll-behavior: smooth; 
  }

  .box {
    min-width: 250px;
    height: 180px; 
    text-align: center;
    justify-content: center;
    padding: 20px;
    font-size: 1.5rem;
    flex: 0 0 auto; 
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem;
  }

  .nca2Container {
    padding: 20px; 
    text-align: center; 
  }


  .nca2-title {
    font-size: 5rem; 
    padding: 10px 20px;
    text-align: center;
  }
}

@media (max-width: 480px) and (min-width:365px) {
  .nca2Container{
    justify-content: center;
  }
  .boxesContainer {
    display: flex;
    flex-wrap: nowrap; 
    overflow-x: auto; 
    gap: 20px; 
    justify-content: flex-start; 
    padding: 10px;
    margin: 0; 
    scroll-behavior: smooth; 
  }

  .box {
    min-width: 250px;
    height: 180px; 
    text-align: center;
    justify-content: center;
    padding: 20px;
    font-size: 1.5rem;
    flex: 0 0 auto; 
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem;
  }

  .nca2Container {
    padding: 20px; 
    text-align: center; 
  }


  .nca2-title {
    font-size: 4rem; 
    padding: 10px 20px;
    text-align: center;
  }
}
@media (max-width:365px){
  .nca2Container{
    justify-content: center;
  }
  .boxesContainer {
    display: flex;
    flex-wrap: nowrap; 
    overflow-x: auto; 
    gap: 20px; 
    justify-content: flex-start; 
    padding: 10px;
    margin: 0; 
    scroll-behavior: smooth; 
  }

  .box {
    width: 200px;
    height: 150px; 
    text-align: center;
    justify-content: center;
    padding: 20px;
    font-size: 1.5rem;
    flex: 0 0 auto; 
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem;
    font-size: 2rem;
  }

  .nca2Container {
    padding: 20px; 
    text-align: center; 
  }


  .nca2-title {
    font-size: 4rem; 
    padding: 10px 20px;
    text-align: center;
  }
}
