@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url(https://db.onlinewebfonts.com/c/83e4a6b639612cd501853be5a7cf97ab?family=Trend+Sans+One+Regular);
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  overflow-y: auto;
  height: 100%;
}
.last-title{
  font-size: 7rem;
  padding: 10px 40px;
  text-shadow: 
    0 0 5px rgba(255, 255, 255, 0.7),
    0 0 10px rgba(255, 255, 255, 0.5),
    0 0 15px rgba(255, 255, 255, 0.3);
  margin-top: 0;
  margin-bottom: 0;   
  font-family: "Trend Sans One Regular";
}
.last-container {
  min-height: 100vh;
  background-color: #19183e;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 150px; 
  position: relative;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  font-family: "League Gothic", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.last-background-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45vw;
  color: #ae267a; 
  z-index: 1;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  pointer-events: none;
  padding-right: 150px;
  opacity: 0.3;
  letter-spacing: 10px;
  text-shadow: 
    0 0 1px #ae267a,
    0 0 2px #ae267a,
    0 0 3px #ae267a,
    0 0 4px #8b1b63;
}

.info-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  z-index: 2;
}
.last-p{
  font-size: 2rem;
  color: white;
  /* margin-left: -38vw; */
}

.info-left, .info-right {
  flex: 1;
  display: flex;
  align-items: left;
  justify-content:flex-start;
  flex-direction: column;
  padding: 20px;
  text-align: left;

}
.last-subtitle {
  font-size: 2.5rem;
  text-align: left; 
  padding-left: 50px;
  box-sizing: border-box;
  font-family: 'Proxima Nova Condensed', sans-serif;
  width: 100%;
}
.info-left{
  font-family: "Trend Sans One Regular";
}


.pink-heading {
  color: #ff4ff3; 
  
  font-size: 1.5rem;
  margin: 0;
  font-family: 'Proxima Nova Condensed', sans-serif;
}

.email, .social {
  margin: 50px 0;
}

.instagram-logo {
  width: 80px; 
  height: auto;
  padding-right: 150px;
  transform: scale(1.25); 
  transform-origin: center; 
}
@media (max-width: 1200px) and (min-width: 850px){
  .last-background-text{
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }
  .last-p{
    margin-left: 0vw;
    font-size: 3rem;
    margin-top: 0vh;
  }
  .pink-heading{
    font-size: 3rem;
  }
}
@media (max-width: 850px) and (min-width: 480px){
  
  .last-background-text{
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }
  .last-p{
    margin-left: 0vw;
  }
  .last-title{
    font-size: 6rem;
  }
}
@media (max-width: 480px) {
  body{
    overflow: hidden;
  }
  .last-container{
    min-height: 30vh;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .last-background-text {
    font-size: 42vw;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
    z-index: 0;
    padding-right: 40px;
    padding-bottom: 55px;
  }

  .info-container {
    margin-top: 35vh;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .info-left, .info-right {
    width: 90%;
    padding: 10px 20px;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .last-title {
    font-size: 2rem;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    
    padding: 0;
  }
  .contact-info{
    display: flex;
    flex-direction: row;
    margin-top: -20vh;
    margin-bottom: 30vh;
  }
  .email{
    margin-right: 20vw;
    margin-left: 10vw;
    
  }

  .last-subtitle {
    font-size: 1.5rem;
    margin-top: -1.0px;
    margin-bottom: 50px;
    text-align: center;
    padding: 0;
  }

  .last-p {
    font-size: 1.5rem;
    margin-left: 0vw;
    margin-top: 2vh;
  
  }

  .pink-heading {
    font-size: 1.2rem;
  }

  .instagram-logo {
    width: 50px;
    height: auto;
    transform: none;
    margin-top: 10px;
  }

  .info-left {
    margin-bottom: 20vh;
    margin-top: -50vh;
  }

  .info-right {
    display: flex;
    flex-direction: row; 
    justify-content: space-between; 
    align-items: center;
    margin-bottom: -30vh;
  }

  .email-section, .social-section {
    display: flex;
    flex-direction: column; 
    text-align: left;
    margin-right: 20px; 
  }

  .social-section {
    display: flex;
    align-items: center; 
  }

  .instagram-logo {
    margin-left: -3vw; 
  }
}
